.card {

    /**
    Global styles and utilities for cards
     */
    @mixin lightText($blow-out: true) {
        color: white;

        @if ($blow-out) {
            img {
                filter: brightness(100);
            }
        }
    }

    @mixin darkText($crush: true) {
        color: #212529;

        @if ($crush) {
            img {
                filter: brightness(0);
            }
        }
    }

    /**
    Employment
     */
    &.torq {
        background: #000000;
        @include lightText;
    }

    &.fortinet {
        background: #DA291C;
        @include lightText(true);
    }
    
    
     &.nextdlp {
        background: #382FF1;
        @include lightText(false);
    }

    &.aws {
        background: #f29e39;
        @include darkText;
    }

    &.komodo {
        background: #243551;
        @include lightText;
    }

    &.vm {
        background: #aa0000; // Dark red from the 'old' Van Mildert logo: https://en.m.wikipedia.org/wiki/File:Van_Mildert_Coll_Durham_shield.svg
        @include lightText(false);
    }

    /**
    Part-Time
     */
    &.hc {
        background: #07b9e8;
        @include darkText;
    }

    &.mlh {
        //background: #e73427; // Red
        //background: #f8b92a; // Yellow
        //background: #1d539f; // Blue
        background: #ca1508; // Dark red (not an official brand colour but required for legibility)
        @include lightText;
    }

    &.fellowship {
        background: #1D539F;
        @include lightText(false);
    }

    &.lsc {
        background: #2a1495;
        @include lightText(false);
    }

    /**
    Education
     */
    &.du {
        background: #611c66;
    }

    &.aws-cp {
        background: #293543;
        @include lightText($blow-out: false);
    }

    &.aws-sa,
    &.aws-dev,
    &.aws-so {
        background: #0c1e91;
        @include lightText($blow-out: false);
    }

    &.aws-sec {
        background: #3b1b8b;
        @include lightText($blow-out: false);
    }

    &.aws-devops-pro {
        background: #016080;
        @include lightText($blow-out: false);
    }

    &.hashicorp-terraform {
        /**
        Based on https://drive.google.com/uc?export=download&id=1M91XdVZINeS6K7Ut7958OhhXa0HK3bjr
        from https://www.hashicorp.com/brand
         */
        background: #F4ECFF;
        @include darkText($crush: false);
    }

    &.hashicorp-vault {
        background: #FFF9CF;
        @include darkText($crush: false);
    }

    &.gcp-ace {
        background: rgb(81, 134, 236);
        @include lightText($blow-out: false);
    }

    &.gcp-pcd {
        background: #f0bf38;
        @include darkText($crush: false);
    }

    /**
     Volunteering
     */
    &.spark {
        background: #0094d6;
        @include lightText;
    }
    
    &.huk {
        background: #e91e63;
        @include lightText;
    }

    &.dh {
        background: #1a1a1a;
        @include lightText($blow-out: false);
    }

    &.compsoc {
        background: #95c5cd;
        @include darkText($crush: false);
    }
}
