@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import 'navbar';
@import 'profile';
@import 'page';
@import 'cards';

.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 1700px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}


body {
    margin-bottom: 50px;
}

h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 700;
    text-align: center;
}

.card {
    align-items: center;
    margin: 20px 30px;
    background-color: white;
    border-radius: .375rem;
    padding: 1.5em;
    font-size: 1.2em;
    display: grid;
    grid-template-columns: 90px auto;
    grid-column-gap: 20px;
}

a {
    color: black;
}

.info {
    p {
        margin-top: 5px;
        margin: 0px;
        text-align: left;
    }

    .title {
        font-size: 16px;
        font-weight: 900;
    }

    .location {
        font-size: medium;
        font-weight: 400;
    }

    .description {
      font-size: small;
      font-weight: 400;
    }

    .dates {
        font-size: small;
        opacity: 0.8;
    }
}

img {
    max-width: 100%;
}
