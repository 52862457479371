.nav-bar {
    width: 100%;
    display: inline-block;
    background-color: #FFF;
    font-family: "Poppins", serif;
    font-weight: 700 !important;
    padding-top: 10px;
}

.nav-content .logo-touch-target {
    display: inline-flex;
    height: 60%;
    width: 350px;
}

.nav-content .nav-logo {
    height: 60px;
    margin: 40px 0 45px 0;
}

.nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-logo img {
    width: 200px;
}

.nav-content nav {
    display: block;
    width: 100%;
    text-align: right;
    padding-top: 0px;
    padding-right: 0px;
}
