h2 {
    font-size: large;
}

body {
    background-color: #22224d;
    background-size: cover;
    width: auto!important; 
    overflow-x: hidden!important;
    font-family: 'Roboto Mono', "Open Sans", sans-serif;
    color: white;
}

.banner {
    padding: 20px 0px 50px 0px;
    height: 150px;
    background-color: #1d539f;
}

.banner .logo {
    max-width:180px;
    max-height:60px;
    width: auto;
    height: auto;
}

.banner .title {
    color: white;
    font-size: 50px;
    font-weight: 700;
}

.page {
    text-align: left;
}


.page h1 {
    text-align: left !important;
    font-weight: 800 !important;
    color: black;
    margin-bottom: 0.7em;
}

.page h2 {
    text-align: left !important;
    font-weight: 800 !important;
    color: black;
    margin-bottom: 0.5em;
}

.page h3 {
    font-weight: 600 !important;
    text-align: left !important;
    color: black;
    font-size: 1.3rem;
}

.page h4 {
    font-weight: 600 !important;
    text-align: center !important;
    color: black;
}

.page p {
    color: black;
    font-weight: 500;
}

.page td {
    color: black;
}

.page th {
    color: black;
}

.page li {
    color: black;
}

.page a {
    color: #0f90fe;
}

.page a:hover {
    color: #0f90fe;
}

.page code {
    color: #17a2b8;
}

hr {
    background-color: black;
    padding: 1px;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
}

@media screen and (max-width: 768px) {
    
    .banner {
        padding: 20px 0px 20px 0px;
        height: 110px;
    }

    .banner .logo {
        max-width:150px;
        max-height:60px;
        width: auto;
        height: auto;
    }

    .banner .title {
        color: white;
        font-size: 30px;
        font-weight: 700;
    }

    .page h1 {
        font-size: 1.5rem !important;
    }

    .page h2 {
        font-size: 1.2rem !important;
    }
    
    .page h4 {
        font-size: 0.8rem !important;
    }
}
