.profile {
    background-color: #f9b660;
    text-align: center;
    min-height: 320px;
    padding: 30px;
    margin-bottom: 50px;
    border-bottom: 6px solid black;

    h1 {
        font-size: 50px;
        font-weight: 700;
        color: #2a3339;
    }
    
    .profile-picture img {
        border-radius: 50%;
        max-width: 100%;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;        
        padding: 20px;
    }
    
    .profile-picture {
        margin-bottom: 30px;
        height: 200px;
    }

    a,
    a:hover,
    a:visited {
        color: rgba(59, 71, 79, 0.92);
    }

    .socials {
        display: flex;
        justify-content: center;
        grid-gap: 24px;

        a:hover {
            text-decoration: none;

            img {
                opacity: 0.8;
            }
        }

        img {
            filter: brightness(0) saturate(100) invert(16%) sepia(17%) saturate(607%) hue-rotate(161deg) brightness(96%);
            transition: opacity 0.2s;
        }
    }
}
